import React from 'react'
import "./work.css"
import Disc from "../../assets/Ellipse 2184.svg"
const Work = () => {
    // const handleDemoClick = () => window.open("https://texelmoda.com/demo-new/","_blank")
    // {
    //     // Redirect to the demo URL
    //     window.location.href = 'https://texelmoda.com/demo-new/';
    //   };
  return (
    <div className='work-container'>
        <div className='work-header-container'>
            <p>HOW WE WORK ?</p>
            <p className='work-subheading'>Transforming Your Vision into Seamless Reality</p>
        </div>
     <div className='work-content'>
        <div className='work-content-container'>
            <div className='work-content-header'>
                <div className='work-content-header-img'>
                    <img src={Disc} alt="disc"/>
                </div>
                <div className='work-content-header-text'>
                    <p>Consultation</p>
                </div>
            </div>
            <div className='work-content-text'>
                <p>We engage in a detailed discussion to understand your needs and goals, crafting a
                 tailored solution with our experts and exploring the possibilities for a comprehensive
                  and effective outcome.</p>
            </div>
        </div>
        <div className='work-content-container'>
            <div className='work-content-header'>
            <div className='work-content-header-img'>
                    <img src={Disc} alt="disc"/>
                </div>
            <div className='work-content-header-text'>
                    <p>Plugin Integration</p>
                </div>
            </div>
            <div className='work-content-text'>
            <p>Choose the ideal plugin model, seamlessly integrating it into your system for effortless functionality and a smooth start to an optimized experience.</p>
            </div>
        </div>
        <div className='work-content-container'>
            <div className='work-content-header'>
                <div className='work-content-header-img'>
                    <img src={Disc} alt="disc"/>
                </div>
                <div className='work-content-header-text'>
                    <p>Optimization & Support</p>
                </div>
            </div>
            <div className='work-content-text'>
            <p>Experience sustained success with our commitment to continuous optimization for efficiency, coupled with ongoing support and updates to ensure your seamless journey in achieving your goals.</p>
            </div>
        </div>
     </div>
        <div className="try-now">
            <button>T<br/>R<br/>Y<br/><br/> N<br/>O<br/>W</button>
        </div>
    </div>
  )
}

export default Work