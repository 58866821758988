import React from 'react'
import "./servicesHero.css"
import ServiceHeroImage from "../../assets/Group 1040.png"
import Button from '../button/Button'
const Serviceshero = () => {
  return (
    <div className='service-hero-container'>
        <div className='services-hero-text-area'>
            <p>Revolutionize the <br/><span style={{color:"#C73C56"}}>customer</span><br/>experience with <span style={{color:"#C73C56"}}>AI</span></p>
            <div className='service-subtext'>
                <p>Elevate Experiences, Transforming Tomorrow with AI Innovation.</p>
            </div>
                <div className='service-hero-button'>
                    <Button text="Try Now"/>
                </div>
            
        </div>
        <div className='service-hero-image'>
            <img src={ServiceHeroImage} alt="group" className='service-img'/>
        </div>
        
    </div>
  )
}

export default Serviceshero