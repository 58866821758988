import React from 'react'
import "./aboutTeam.css"
// import XLOGO from "../../assets/Social Icons (6).svg";
import LinkedIn from "../../assets/Social Icons (2).svg";
import Dhanush from "../../assets/dhanush.jpeg";
import Srishti from "../../assets/srishti.jpeg";
import Savio from "../../assets/savio.jpeg";
import vignarth from "../../assets/vignarth.jpeg"
const AboutTeam = () => {
    // const handleImage = ()=>{
       
    // }
  return (
    <div className='team-container'>
        <div className='team-heading'>
            <p>Our Passionate Team</p>
        </div>
        <div className='team-area'>
            <div className='one-card'>
                <div className='team-photo'>
                <img src={Srishti} alt="srishti pic" style={{height:"100px",width:"100px",borderRadius:"50%"}}/>
                </div>
                <div className='team-name'>
                        <p>Srishti B</p>
                </div>
                <div className='team-position'>
                    <p>Founder</p>
                </div>
                <div className='team-social'>
                    {/* <img src={XLOGO} alt="x-logo" height="20px" width="20px" style={{color:"#fff"}}/> */}
                    <img src={LinkedIn} alt="x-logo" height="20px" width="20px" onClick={()=> window.open('https://www.linkedin.com/in/shristiblockmagix?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',"_blank")}/>

                </div>
            </div>
            {/* <div className='one-card'>
                <div className='team-photo'>
                <svg xmlns="http://www.w3.org/2000/svg" width="94" height="94" viewBox="0 0 214 214" fill="none">
                    <circle cx="107" cy="107" r="107" fill="#D9D9D9" fill-opacity="0.26"/>
                </svg>
                </div>
                <div className='team-name'>
                        <p>Rockline George</p>
                </div>
                <div className='team-position'>
                    <p>Founder and managing</p>
                </div>
                <div className='team-social'>
                    <img src={XLOGO} alt="x-logo" height="20px" width="20px"/>
                    <img src={LinkedIn} alt="x-logo" height="20px" width="20px"/>
                </div>
            </div> */}
            <div className='one-card'>
                <div className='team-photo'>
                <img src={Dhanush} alt="dhanush pic" style={{height:"100px",width:"100px",borderRadius:"50%"}}/>
                </div>
                <div className='team-name'>
                        <p>Dhanush Manian</p>
                </div>
                <div className='team-position'>
                    <p>Business Team</p>
                </div>
                <div className='team-social'>
                    {/* <img src={XLOGO} alt="x-logo" /> */}
                    <img src={LinkedIn} alt="x-logo" onClick={()=> window.open('https://www.linkedin.com/in/dhanushmanian',"_blank")}/>
                </div>
            </div>
            <div className='one-card'>
                <div className='team-photo'>
                <img src={Savio} alt="dhanush pic" style={{height:"100px",width:"100px",borderRadius:"50%"}}/>
                </div>
                <div className='team-name'>
                        <p>Savio Joseph</p>
                </div>
                <div className='team-position'>
                    <p>Business Team</p>
                </div>
                <div className='team-social'>
                    {/* <img src={XLOGO} alt="x-logo" /> */}
                    <img src={LinkedIn} alt="x-logo" onClick={()=> window.open('https://www.linkedin.com/in/savio-joseph-28a818120/',"_blank")}/>
                </div>
            </div>
            <div className='one-card'>
                <div className='team-photo'>
                <img src={vignarth} alt="dhanush pic" style={{height:"100px",width:"100px",borderRadius:"50%"}}/>
                </div>
                <div className='team-name'>
                        <p>Vignarth Gowda</p>
                </div>
                <div className='team-position'>
                    <p>Business Team</p>
                </div>
                <div className='team-social'>
                    {/* <img src={XLOGO} alt="x-logo" /> */}
                    <img src={LinkedIn} alt="x-logo" onClick={()=> window.open('https://www.linkedin.com/in/vignarth-gowda-1692791a3/',"_blank")}/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutTeam