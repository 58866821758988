import React from 'react'
import "./hero.css"
import Button from '../button/Button';
const Hero = () => {
  return (
    <div className='Hero-container'>
        <div className='hero-heading'>
            <p>Elevate Your Look with ProActing Plans</p>
        </div>
        <div className='hero-para'>
            <p>Unlock a world of style possibilities with our ProActing Plans for Virtual Try-On.</p>
            <p>Elevate your fashion experience and try it now with</p>
            <p>Our exclusive trial offer!</p>
        </div>
        <div className='hero-button'>
            <Button text="Try Now"/>
        </div>
    </div>
  )
}

export default Hero