import React from 'react'
import "./Card.css"
import Right from "../../assets/Right 2.svg"
import { useNavigate} from 'react-router-dom';
const Card = () => {
    const navigate = useNavigate();
  return (
    <div className="card-area">
        <div className='card-container'>
        <div className='card-header'>
            <p className='card-header-text'>
                Individual
            </p>
            {/* <div className='card-header-tag'>

            </div> */}
        </div>
        <div className="card-subheading">
            <p>For 3D creators, designers, and students</p>
            <p>venturing into digital fashion exploration.</p>
        </div>
        <div className='card-user-info'>
            <p>Per Month</p>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>80+ Measurements</p>
            </div>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>Data Export CSV</p>
            </div>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>3D Model View</p>
            </div>
        </div>
        <div className='card-user-info'>
            <p>Per Month</p>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>3D Model Export</p>
            </div>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>Basic Support</p>
            </div>
        </div>
        <div className="card-button">
        <button onClick={()=>navigate('/contact')}>Connect now</button>
        </div>
        </div>
        <div className='card-container'>
        <div className='card-header'>
            <p className='card-header-text'>
                Business
            </p>
            <div className='card-header-tag'>
                {/* <img src="https://s3-alpha-sig.figma.com/img/f69d/418b/fc9580b74011d05302896794c4b9e3fb?Expires=1706486400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=mThMGM6FOc9k18XLo6QlQUuiI4kNVW0l~KAanlhDbFHXtRlhOVhknjBLapYYRaWRhoCobTVPKUYKmfb4D03USwwx3hjaPDJPa45Ke7Ltr5hMSqev8SoFILEIa4XDnTqpJmKKk~DmAVsk4EKcC3d8GZY-qSUlwbAKxnIm6spUNQosxdaE-K73LJAz3GLqu1tPkJvsEMRP4aaNoHMhdu6HtcNRdCv7-tY5B-Q0o4a~wWN1FzqGQyDU3FAcIuQD3aDug0EObPWJf~qc9u-Wl4UcoxRx4bimkGUdSjULXjYsLpr5DF8-MRa4qfzKg0eiuudCKCCi-OT8XK835dYJOChVvQ__" alt="star"/> */}
                <p>Best Seller</p>
            </div>
        </div>
        <div className="card-subheading">
            <p>Crafting Style, Redefining Design: Your Rich</p>
            <p>Toolset for Fashion Innovation and Personalization.</p>
        </div>
        <div className='card-user-info'>
            <p>Per Month</p>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>80+ Measurements</p>
            </div>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>Data Export CSV</p>
            </div>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>3D Model View</p>
            </div>
        </div>
        {/* <div className='card-user-info'>
            <p>Per user per month</p>
        </div> */}
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>Web Widget Integration</p>
            </div>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>Real Time Notification</p>
            </div>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>SMS/Email Templates</p>
            </div>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>Web Widget Customization</p>
            </div>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>3D Model Export</p>
            </div>
        </div>
        <div className="card-button">
        <button onClick={()=>navigate('/contact')}>Connect now</button>
        </div>
        </div>
        <div className='card-container'>
        <div className='card-header'>
            <p className='card-header-text'>
                Enterprise
            </p>
            {/* <div className='card-header-tag'>

            </div> */}
        </div>
        <div className="card-subheading">
            <p>Exclusive Features for Business</p>
            <p>Optimization and Ambitious Goals.</p>
        </div>
        <div className='card-user-info'>
            <p>Per Month</p>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>80+ Measurements</p>
            </div>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>Data Export CSV</p>
            </div>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>Real Time Notification</p>
            </div>
        </div>
        {/* <div className='card-user-info'>
            <p>Per user per month</p>
        </div> */}
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>API</p>
            </div>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>Web Widget Customization</p>
            </div>
        </div>
        <div className='card-pointer'>
            <div className='pointer-svg'>
                <img src={Right} alt="icon"/>
            </div>
            <div className='pointer-text'>
                <p>3D Model Export</p>
            </div>
        </div>
        <div className="card-button">
            <button onClick={()=>navigate('/contact')}>Connect now</button>
        </div>
        </div>
    </div>
  )
}

export default Card