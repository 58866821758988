import React from 'react'
import "./fashion.css"
import Image from "../../assets/fashion.PNG"
const Fashion = () => {
  return (
    <div className='fashion-container'>
        <div className='fashion-header'>
            <div className='fashion-bar'>
            </div>
            <div className='fashion-header-text'>
                Magix.Mirror
                {/* <p className='fashion-header-sub'>Powered By Texel.Moda</p> */}
            </div> 
           
        </div>
        <div className="fashionSubheading">
          {/* <p>Powered By Texel.Moda</p> */}
        </div>
        <div className='fit-content'>
        <div className='fashion-image-section'>
            <img src={Image} alt="fashion"/>
            {/* <p className='image-text'>FASHION</p> */}
        </div>
        <div className='bullets'>
            <ul>
              <li>Increase average order value and in-store traffic.</li>
              <li>Personalize in-store customer experience.</li>
              <li>Develop seamless omnichannel experience.</li>
              <li>Increase brand awareness with innovative experience solutions.</li>
            </ul>
        </div>
        </div>
    </div>
  )
}

export default Fashion