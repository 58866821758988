import React from 'react'
import Navbar from '../navbar/Navbar'
import AboutHero from './AboutHero'
// import AboutLaunch from './AboutLaunch'
import Why from './Why'
import Footer from '../footer/Footer'
import Work from './Work'
// import Fashion from './Fashion'
import AboutLaunch from './AboutLaunch'
import AboutTeam from './AboutTeam'

const Index = () => {
  return (
    <div>
        {/* About */}
        <Navbar/>
        <AboutHero/>
        <AboutLaunch/>
        <Why/>
        <Work/>
        {/* <Fashion/> */}
        <AboutTeam/>
        <Footer/>
    </div>
  )
}

export default Index