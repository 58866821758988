import React from 'react'
// import { useNavigate} from 'react-router-dom';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import "./faq.css"
// const accHead = {
//   fontFamily:"Urbanist",
//   fontSize:"28px",
//   border:"none",
//   "@media only screen and (max-width: 600px)":{
//     fontSize:"11px"
//   }
// }
// const accDet = {
//   fontFamily:"Urbanist",
//   fontSize:"16px",
//   "@media only screen and (max-width: 600px)":{
//     fontSize:"9px"
//   }
// }
const Faq = () => {
  // const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  return (
    <div className='faq-container'>
      {/* <div className='faq-header'>
        <p>FREQUENTLY ASKED QUESTIONS</p>
      </div>
      <div className='accordion'>
        <Accordion defaultExpanded sx={{boxShadow:"none",paddingTop:"5%"}}>
          <AccordionSummary
            expandIcon={<KeyboardArrowRightIcon sx={{color:"#C73C56"}}/>}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography sx={accHead}>Expanded by default</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{}}>
            <Typography sx={accDet}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{boxShadow:"none",paddingTop:"5%"}}>
          <AccordionSummary
            expandIcon={<KeyboardArrowRightIcon sx={{color:"#C73C56"}}/>}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography sx={accHead}>Expanded by default</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{}}>
            <Typography sx={accDet}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{boxShadow:"none",paddingTop:"5%"}}>
          <AccordionSummary
            expandIcon={<KeyboardArrowRightIcon sx={{color:"#C73C56"}}/>}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography sx={accHead}>Expanded by default</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{}}>
            <Typography sx={accDet}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div> */}
      <div className='question-div'>
        <div className='question-header'>
            <p>Still have questions?</p>
        </div>
        <div className='question-subheading'>
            <p>Connect with our team </p>
        </div>
        <div className='question-button'>
            <button onClick={scrollToTop} style={{cursor:"pointer"}}>Connect Us</button>
        </div>
      </div>
    </div>
  )
}

export default Faq