import React from 'react'
import Arrow from "../../assets/Right 1.svg"
import Group from "../../assets/Group 3.svg"
import "./aboutLaunch.css"

const AboutLaunch = () => {
  return (
    <div className='about-launch-container'>
        <div className='arrow-div'>
                <div className='arrow-div-text'>
                    <p>Lauch <br/>With Ease</p>
                    <p style={{color:"#C73C56",fontSize:"15px"}}>Coming Soon</p>
                </div>
                <div className='arrow-img-div'>
                    <img src={Arrow} alt="arrow" />
                </div>
        </div>
        <div className='box-div'>
            <div className='box'>
                {/* <div className='box-head'>
                    <div className="box-content-image-container">
                        <img src={Group} alt="group"/>
                        </div>
                </div>
                <div className="box-heading">
                    <p>Elevated shopping experience.</p>
                </div>
                <div className="box-content">
                    <p>"Dive into a captivating shopping journey with Magix Fit's enhanced 3D visuals and AR, ensuring interactive, enjoyable, and memorable virtual try-on sessions."</p>
                </div> */}
            </div>
            <div className='box' style={{background:"#181818"}}>
                <div className='box-head'>
                    <div className="box-content-image-container">
                        <img src={Group} alt="group"/>
                        </div>
                </div>
                <div className="box-heading">
                    <p>Time Saving</p>
                </div>
                <div className="box-content">
                    <p>Magix Fashion saves time, boosts confidence—skip store visits. Visualize each piece style instantly with virtual try-on, simplifying shopping decisions.</p>
                </div>
            </div>
            <div className='box'>
                <div className='box-head'>
                    <div className="box-content-image-container">
                        <img src={Group} alt="group"/>
                        </div>
                </div>
                <div className="box-heading">
                    <p>Effortless Virtual Try-On</p>
                </div>
                <div className="box-content">
                    <p>Transform your shopping with Magix Fashion effortless virtual try-on. Skip fitting room queues and tedious try-outs – visualize how a piece will look on you instantly from home.</p>
                </div>
            </div>
            <div className='box' style={{background:"#181818"}}>
                <div className='box-head'>
                    <div className="box-content-image-container">
                        <img src={Group} alt="group"/>
                        </div>
                </div>
                <div className="box-heading">
                    <p>Elevated experience</p>
                </div>
                <div className="box-content">
                    <p>Dive into a captivating shopping journey with Magix Fashion's enhanced 3D visuals and AR, ensuring interactive, enjoyable, and memorable virtual try-on sessions.</p>
                </div>
            </div>
            <div className='box'>
                <div className='box-head'>
                    <div className="box-content-image-container">
                        <img src={Group} alt="group"/>
                        </div>
                </div>
                <div className="box-heading">
                    <p>Real-time style suggestions</p>
                </div>
                <div className="box-content">
                    <p>Get instant style suggestions from Magix Fashion's algorithm, ensuring you stay on-trend with personalized fashion insights based on your preferences.</p>
                </div>
            </div>
            <div className='box' style={{background:"#181818"}}>
                <div className='box-head'>
                    <div className="box-content-image-container">
                        <img src={Group} alt="group"/>
                        </div>
                </div>
                <div className="box-heading">
                    <p>Fashion Exploration</p>
                </div>
                <div className="box-content">
                    <p>Experiment fearlessly with Magix Fashion, exploring diverse fashion choices to discover your unique signature look effortlessly.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutLaunch