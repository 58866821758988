import React from 'react'
import "./tech.css";
const Tech = () => {
  return (
    <div className='tech-container'>
        <div className='tech-header'>
            <p>Our Technologies</p>
        </div>
        <div className='tech-subheading'>
          <p>By leveraging cutting-edge Generative Adversarial Networks (GAN) technology, our solution at MAGIX.FASHION empowers ecommerce fashion brands with unparalleled capabilities. Seamlessly blending innovation and practicality, we redefine the online shopping experience, ensuring brands achieve optimal engagement and customer satisfaction.</p>
        </div>
    </div>
  )
}

export default Tech