import React from 'react'
import "./abouthero.css"
// import Button from '../button/Button';
// import blockmagix from "../../assets/blockmagix.png"
import { useTypewriter} from "react-simple-typewriter";
import vid from "../../assets/Cloth Video .mp4"
const AboutHero = () => {
  const [text1] = useTypewriter({
    words: ["FORWARD"],
    loop: true,
    typeSpeed: 50,
    deleteSpeed: 90,
    delaySpeed: 1500,
  });
  const [text2] = useTypewriter({
    words: ["FUTURE"],
    loop: true,
    typeSpeed: 50,
    deleteSpeed: 90,
    delaySpeed: 1500,
  });
  return (
    <div className='about-hero-container'>
        <div className='about-hero-text'>
         
            <p className='first-p'>WE - ARE<div className='about-text-small'>THE FUTURE IS HERE</div></p>
            <p className='second-p'>FASHION <span className='brown'>{text1}</span></p>
            <p className='third-p'><span className='brown'>{text2}</span> READY</p>
            {/* <div className='about-btn'><Button text="Try Now"/></div> */}
        </div>  
        <div className='hero-video'>
            <video src={vid} type="video/mp4" loop muted autoPlay></video>
        </div> 
    </div>
  )
}

export default AboutHero