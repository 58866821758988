import React from "react";
import {Routes, Route} from "react-router-dom";
import "./App.css";
import Pricing from "./components/pricing/Pricing";
import Contact from "./components/contact/Index";
import About from "./components/about/Index";
import Services from "./components/service/Services";

function App() {
  return (

        <div>
        <Routes>
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={<About/>}/>
          <Route path="/services" element={<Services/>}/>
        </Routes>
      </div> 
  );
}

export default App;
