import React from 'react'
import "./button.css"
const Button = ({text}) => {
    // const handleDemoClick = () => window.open('https://texelmoda.com/demo-new/','_blank')
    //  {
    //     // Redirect to the demo URL
    //     window.location.href = 'https://texelmoda.com/demo-new/';
    //   };
  return (
    <div className="demoButton">
        <button>{text} </button>
    </div>
  )
}

export default Button