import React from 'react'
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import Hero from './Hero';
import Plans from './Plans';
const Pricing = () => {
  return (
    <div>
        <Navbar/>
        <Hero/>
        <Plans/>
        <Footer/>
    </div>
  )
}

export default Pricing;