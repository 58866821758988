import React from 'react'
import "./magixfit.css"
import Image from "../../assets/image.png"
const Fashion = () => {
  return (
    <div className='fit-container'>
        <div className='fit-header'>
            <div className='fashion-header-text'>
                Magix.Fit
                {/* <p className='fashion-header-sub'>Powered By Texel.Moda</p> */}
            </div> 
            <div className='fashion-bar'>
            </div>
        </div>
        <div className="fitSubheading">
          {/* <p>Powered By Texel.Moda</p> */}
        </div>
        <div className='mirror-content'>
        <div className='bullets'>
            <ul>
              <li>Virtual try-on, shop clothes without visiting stores.</li>
              <li>Brands integrate Magix.Fit for mobile try-ons.</li>
              <li>Boosts engagement, lifts conversion in integration.</li>
              <li>Magix.Fit: Customize for brand aesthetics in try-ons.</li>
            </ul>
        </div>
        <div className='fit-image-section'>
            <img src={Image} alt="fashion"/>
            {/* <p className='image-text'>FASHION</p> */}
        </div>
        </div>
    </div>
  )
}

export default Fashion