import React from 'react'
import "./plans.css"
import Card from './Card'
const Plans = () => {
  return (
    <div className='plans-container'>
        <div className='plans-heading'>
            <p>Our Pricing Plans </p>
        </div>
        {/* <div className='plans-subheading active'>
            <p>Monthly</p>
            <p>Yearly</p>
        </div> */}
          <Card/>
    </div>
  )
}

export default Plans