import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuIcon from '../../assets/Menu.svg';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Logo from "../../assets/mfs.gif"
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import { Link } from 'react-router-dom';
// import Divider from '@mui/material/Divider';
import './navbar.css';
import Button  from '../button/Button';
// import { useHistory } from 'react-router-dom';
const Navbar = () => {
  // const history = useHistory();
    const navigate = useNavigate();
   
    
    const location = useLocation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    // const [isServicesHovered, setIsServicesHovered] = useState(false);
    // const handleClick = () => {
    //   history.push('#magixMirror');
    // };
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  // const handleChange = ()=>{
  //   toggleDrawer();
  //   navigate('/services')
  // }
  React.useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    const toggleDrawer = () => {
      setDrawerOpen(!drawerOpen);
    };
  
    const handleDrawerItemClick = (path) => {
      navigate(path);
      toggleDrawer();
    };
  
    const isActive = (path) => {
      return location.pathname === path;
    };
    
    
    return (
      <div className="container">
        <div className="logo">
          {/* <p>magix.fashion</p> */}
          
           
        </div>
        <div>
          <ul className="links" >
            <li>
            <img src={Logo} alt="logo" 
            style={{width:"250px",
            height:"90px",
            cursor:"pointer"
            }}
            onClick={() => navigate('/')} 
            />
            </li>
            <li className={`link ${isActive('/') ? 'active' : ''}`} onClick={() => navigate('/')}>
              about
            </li>
            <li
                        className={`link ${isActive('/services') ? 'active' : ''}`}
                        // onMouseEnter={() => setIsServicesHovered(true)}
                        onClick={() => navigate('/services')}
                        // onMouseLeave={() => setIsServicesHovered(false)}
                    >
              services
              {/* {isServicesHovered && (
                            <ul className="sub-links">
                                <li onClick={() => navigate('/#magixMirror')}> Magix fit</li>
                                
                                <li onClick={()=> navigate('#magixFit')}> Magix Mirror</li>
                            </ul>
                        )} */}
            </li>
            <li className={`link ${isActive('/pricing') ? 'active' : ''}`} onClick={() => navigate('/pricing')}>
              pricing
            </li>
            <li className={`link ${isActive('/contact') ? 'active' : ''}`} onClick={() => navigate('/contact')}>
              contact
            </li>
          </ul>
        </div>
          {!isMobile &&<Button text="Try Demo"/>}
        <div className="hamburger" onClick={toggleDrawer}>
        <img src={Logo} alt="logo" style={{width:"250px",left:"-570px",height:"90px"}} />
          <img src={MenuIcon} alt="menu icon" />
        </div>
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
  <List sx={{ width: "40vw" }}>
    {['/', '/services', '/pricing ', '/contact'].map((path, index) => (
      <React.Fragment key={index}>
        {/* {path === "/services" ? (
           <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">services</InputLabel>
  <Select
  labelId="demo-simple-select-label"
  id="demo-simple-select"
  label="services"
  onChange={handleChange}
>
  <MenuItem value="/#magixMirror">Magix.fit</MenuItem>
  <MenuItem value="/#magixFit">Magix.Mirror</MenuItem>
</Select>
</FormControl>
        ) : ( */}
          <ListItem
            button
            className={isActive(path) ? 'active' : ''}
            onClick={() => handleDrawerItemClick(path)}
            sx={{ textAlign: "center" }}
          >
            <ListItemText>
              <Typography>{path !== "/" ? path.slice(1).charAt(0).toUpperCase() + path.slice(2) : "About"}</Typography>
            </ListItemText>
          </ListItem>
        {/* )} */}
      </React.Fragment>
    ))}
  </List>
</Drawer>

      </div>
    );
  };
  
  export default Navbar;
