import React from 'react'
import "./why.css";
import Group from "../../assets/Group 3.svg"
const Why = () => {
  return (
    <div className='why-container'>
        <div className='why-header'>
            {/* <p>INDUSTRIES</p> */}
            <p className='bold-header'><b>WHY MAGIX FASHION ?</b></p>
            <p>One Product, Endless Business Possibilities.</p>
        </div>
        <div className='why-content-wrapper'>
            <div className='why-content-area'>
                <div className='why-content-heading'>
                    <div className='why-content-image-container'>
                        <img src={Group} alt="group"/>
                    </div>
                </div>
                <div className='why-contnet-subheading'>
                        <p>Unmatched quality and productivity</p>
                </div> 
                <div className='why-content'>  
                <p>We deliver top-notch 3D models, ensuring seamless and life like visuals while optimizing them for light-weight performance in immersive experience.</p>
                </div>
            </div> 
            <div className='why-content-area'>
                <div className='why-content-heading'>
                    <div className='why-content-image-container'>
                        <img src={Group} alt="group"/>
                    </div>
                </div>
                <div className='why-contnet-subheading'>
                        <p>Comprehensive solution partner</p>
                </div> 
                <div className='why-content'>  
                    <p>We elevate a brand's digital strategy by delivering transformative 3D and AR experiences at every
                    customer journey, from awareness and consideration to purchase and loyalty.</p>
                </div>
            </div> 
            <div className='why-content-area'>
                <div className='why-content-heading'>
                    <div className='why-content-image-container'>
                        <img src={Group} alt="group"/>
                    </div>
                </div>
                <div className='why-contnet-subheading'>
                        <p>Effortless integration</p>
                </div> 
                <div className='why-content'>  
                    <p>We provide low-code solutions with minimal time and labor expenses, offering a ready-made Virtual Try-On solution that seamlessly integrates into a website with just a few lines
                    of code.</p>
                </div>
            </div> 
            <div className='why-content-area'>
                <div className='why-content-heading'>
                    <div className='why-content-image-container'>
                        <img src={Group} alt="group"/>
                    </div>
                </div>
                <div className='why-contnet-subheading'>
                        <p>Outstanding client support</p>
                </div> 
                <div className='why-content'>  
                    <p>Our expert team, specializing in the unique needs of luxury brands, thoroughly explores 
                    our partner's business, tailors solutions to their requirements, and promptly and
                    effectively fulfills them.</p>
                </div>
            </div> 
        </div>
    </div>
  )
}

export default Why