import React from 'react'
import "./footer.css"
import { useNavigate} from 'react-router-dom';
// import X from "../../assets/Social Icons (6).svg"
import Insta from "../../assets/Social Icons (5).svg"
import LinkedIn from "../../assets/Social Icons (2).svg"
// import Github from "../../assets/Social Icons (3).svg"
// import Discord from "../../assets/Social Icons (4).svg"
// import blockmagix from "../../assets/blockmagix.png"
import {useMediaQuery} from "@mui/material";
const Footer = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div className="footer-container">
        <div className='footer-logo'>
            <p>magix.fashion</p>
            <p style={{padding:"1%"}}>Venture of BlockMagix</p>
        </div>
       {!isMobile ?( <div className="content">
            <div className='resource'>
                <div className='resource-head'>
                    <p>Resources</p>
                    <ul className='ul-list'>
                        <li className='verticle-list'
                         onClick={() => navigate('/')}
                        >
                            About
                        </li>
                        <li className='verticle-list'
                         onClick={() => navigate('/services')}
                        >
                            Services
                        </li>
                        {/* <li className='verticle-list'
                         onClick={() => navigate('/pricing')}
                        >
                            Pricing
                        </li> */}
                        <li className='verticle-list'
                         onClick={() => navigate('/contact')}
                        >
                            Contact Us
                        </li>
                    </ul>
                </div>
            </div>
            <div className='resource'>
                <div className='resource-head'>
                    <p>Contact Us</p>
                    <ul className='ul-list'>
                        <li className='verticle-list'>
                            contact@magixfashion.in
                        </li>
                    </ul>
                </div>
            </div>
            <div className="resource">
                <div className="resource-head">
                    <p>Follow Us</p>
                </div>
            <div className='social'>
                {/* <img src={X} alt="X"/> */}
                <img src={Insta} alt="Instagram" onClick={()=> window.open('https://www.instagram.com/magix.fashion?utm_source=qr&igsh=c3dmYm04MDlscGdy',"_blank") }/>
                <img src={LinkedIn} alt="LinkedIn" onClick={()=> window.open('https://www.linkedin.com/company/magix-fashion/',"_blank") }/>
                {/* <img src={Github} alt="Github"/>
                <img src={Discord} alt="Discard"/> */}
            </div>
            </div>
        </div>) : (<div className="content">
            <div className='resource'>
                <div className='resource-head'>
                    <p>Resources</p>
                    <ul className='ul-list'>
                        <li className='verticle-list'
                         onClick={() => navigate('/')}
                        >
                            About
                        </li>
                        <li className='verticle-list'
                         onClick={() => navigate('/services')}
                        >
                            Services
                        </li>
                        {/* <li className='verticle-list'
                         onClick={() => navigate('/pricing')}
                        >
                            Pricing
                        </li> */}
                        <li className='verticle-list'
                         onClick={() => navigate('/contact')}
                        >
                            Contact Us
                        </li>
                    </ul>
                </div>
            </div>
            <div className='resource fl'>
                <div className='resource-head'>
                    <p>Contact Us</p>
                    <ul className='ul-list'>
                        <li className='verticle-list'>
                            contact@magixfashion.in
                        </li>
                    </ul>
                </div>
                <div className='follow'>
                    <div className="resource-head">
                        <p>Follow Us</p>
                    </div>
                    {/* <div className='social'> */}
                    <ul className='f1 ul-list' style={{display:"flex",gap:"15px"}}>
                      <li className='verticle-list social'> <img src={Insta} alt="Instagram" onClick={()=> window.open('https://www.instagram.com/magix.fashion?utm_source=qr&igsh=c3dmYm04MDlscGdy',"_blank") }/></li> 
                      <li className='verticle-list social'><img src={LinkedIn} alt="LinkedIn" onClick={()=> window.open('https://www.linkedin.com/company/magix-fashion/',"_blank") }/></li>  
                      </ul>
                    {/* </div> */}
                </div>
            </div>
        </div>)}
    </div>
  )
}

export default Footer