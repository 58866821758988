import React from 'react'
import "./useCase.css"
import Home from "../../assets/Home 1.svg"
const Usecase = () => {
  return (
    <div className='usecase-container'>
        <div className='useCase-header'>
            <div className='usecase-heading'>
                <p>USE CASES</p>
            </div>
            <div className='usecase-subheading'>
                <p>Diverse Use Cases in Fashion</p>
            </div>
            <div className='usecase-heading-bar'>
                
            </div>
        </div>
        <div className='useCase-card-container'>
            <div className='useCase-card'>
                <div className='useCase-card-header'>
                    <img src={Home} alt="home"/>
                </div>
                <div className='usecase-card-heading'>
                    <p>Enhance Conversions With Virtual try-on</p>
                </div>
                <div className='useCase-card-subheading'>
                    <p>Engage users with interactive virtual try-ons for 
                        informed purchase decisions and higher
                        conversion rates.</p>
                </div>
            </div>
            <div className='useCase-card'>
                <div className='useCase-card-header'>
                    <img src={Home} alt="home"/>
                </div>
                <div className='usecase-card-heading'>
                    <p>Minimize product return rates</p>
                </div>
                <div className='useCase-card-subheading'>
                    <p>Enhance customer satisfaction by minimizing the need for product returns. 
                        By ensuring a better match between expectations and reality.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Usecase