import React from 'react'
import Navbar from "../navbar/Navbar"
import Footer from "../footer/Footer"
import Serviceshero from './Serviceshero'
import Usecase from './Usecase'
import Tech from './Tech'
import Fashion from '../about/Fashion'
import MagixFit from "./MagixFit"
const Services = () => {
  return (
    <div>
        <Navbar/>
        <Serviceshero/>
        <Usecase/>
        <Tech/>
        <div id="magixFit">
          <MagixFit/>
        </div>
        <div id="magixMirror">
          <Fashion/>
        </div>
        <Footer/>
    </div>
  )
}

export default Services