import React from 'react'
import "./contacthero.css"
import XLOGO from "../../assets/Social Icons (6).svg"
import InstaLogo from "../../assets/Social Icons (5).svg"
import TelegramLogo from "../../assets/Vector.svg"
import GithubLogo from "../../assets/Social Icons (3).svg"
import DiscordLogo from "../../assets/Social Icons (4).svg"
// import { base_api } from '../../api/https'
// import axios from 'axios'
const ContactHero = () => {
    const [formData, setFormData] = React.useState({
        name: '',
        email: '',
        phone: '',
        message: '',
      });
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          console.log('Email sent successfully!',formData);
        } catch (error) {
          // Handle error
          console.error('Error sending email:', error);
        }
      };  
    // const api = `${base_api}/email`
    // console.log(api);
  return (
    <div className='contact-hero-area'>
        <div className='contact-hero-container'>
            <div className='contact-hero-header'>
                <p>Transform Your Virtual Try-On Journey </p>
            </div>
            <div className='contact-hero-subheading'>
                <p>Ready to transform your virtual try-on experience?<br/>
                 Reach out through our contact form and let's bring<br/>
                your style vision to life!</p>
            </div>
                <form className='contact-form' onSubmit={handleSubmit}>
                    <div className='contact-form-header'>
                        <p>Connect With Us</p>
                        <div className='contact-form-header-active-bar'></div>
                    </div>
                    <div className='input-bar'>
                            <input 
                            type='text' 
                            placeholder='Name'
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            />
                    </div>
                    <div className='input-bar'>
                            <input 
                            // type='text' 
                            placeholder='Email'
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            />
                    </div>
                    <div className='input-bar'>
                            <input 
                            type='text' 
                            placeholder='Phone Number'
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                            />
                    </div>
                    <div className='input-bar'>
                            <input 
                            type='text' 
                            placeholder='Type Your Message Here'
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                            />
                    </div>
                    <div className='contact-form-button'>
                        <button type='submit'>Submit</button>
                    </div>
                </form>
            <div className='contact-social'>
                <img src={XLOGO} alt="Xlogo"/>
                <img src={InstaLogo} alt="Xlogo"/>
                <img src={GithubLogo} alt="Xlogo"/>
                <img src={DiscordLogo} alt="Xlogo"/>
                <img src={TelegramLogo} alt="Xlogo"/>
            </div>
        </div>
    </div>
  )
}

export default ContactHero