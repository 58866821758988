import React from 'react'
import Navbar from '../navbar/Navbar';
import ContactHero from './ContactHero';
import Footer from '../footer/Footer';
import Faq from './Faq';
const Index = () => {
  return (
    <div>
      <Navbar/>
      <ContactHero/>
      <Faq/>
      <Footer/>
    </div>
  )
}

export default Index